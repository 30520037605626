import * as React from "react";
import { useForm } from "react-hook-form";

import { Worker } from "../util";
import Keypad from "./keypad";

interface PasswordFormProps {
  worker: Worker;
  onDone: (password: string) => void;
  onClose: () => void;
}

const PasswordForm: React.FC<PasswordFormProps> = (props) => {
  const { worker, onDone, onClose } = props;
  const { register, watch, formState: { errors }, handleSubmit, setError, setValue } = useForm();

  const formSubmitted = ({ password }: { password: string }) => {
    fetch(`/worker/${worker.id}/authenticate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      if (data.status) {
        onClose();
        onDone(password);
      } else {
        setError("password", {
          message: "Passwort konnte nicht verifiziert werden!"
        })
      }
    });
  }

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box">
          <form onSubmit={handleSubmit(formSubmitted)} className="p-4">
            <h4 className="title is-4">Password eingeben</h4>
            <hr/>

            {(errors.password) && (
              <article className="message is-danger">
                <div className="message-body">
                  {errors.password.message?.toString()}
                </div>
              </article>
            )}

            <div className="field">
              <label className="label">Name</label>
              <div className="control is-expanded">
                <input type="text" className="input is-static" readOnly={true} value={worker.name} />
              </div>
            </div>

            <div className="field">
              <label className="label">Passwort</label>
              <div className="control is-expanded">
                <input
                  className="input"
                  type="password"
                  {...register("password", { required: true })}
                />
              </div>
            </div>

            <div className="field">
              <Keypad
                onChange={(value) => {
                  setValue("password", value);
                }}
              />
            </div>

            <div className="field is-grouped">
              <div className="control">
                <button type="submit" className="button is-link" disabled={(watch("password") || "").length == 0}>
                  Weiter
                </button>
              </div>
              <div className="control">
                <button type="reset" className="button is-link is-light" onClick={onClose}>
                  Abbrechen
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordForm;
