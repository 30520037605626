import * as React from "react";
import { Worker, mapAbsence } from "../util";

interface PresenceListProps {
  workers: Array<Worker>;
  onWorkerClicked: (id: string, e?: React.MouseEvent<HTMLTableRowElement>) => void;
}

const PresenceList: React.FC<PresenceListProps> = (props) => {
  const { workers, onWorkerClicked } = props;

  const presentWorkers = workers.filter(({ isPresent }) => isPresent);
  const notCheckedInWorkers = workers.filter(({ isPresent, absence }) => !isPresent && absence == null);
  const absentWorkers = workers.filter(({ absence }) => absence != null);

  return (
    <div>
      {(presentWorkers.length > 0) && (
        <>
          <h3 className="sectionheader">Eingecheckt</h3>

          {presentWorkers.map(({ id, name, project }, i) => {
            return (
              <div key={i} className="columns tight is-clickable" onClick={onWorkerClicked.bind(null, id)}>
                <div className="column is-half">{name}</div>
                <div className="column is-half has-text-right">{project}</div>
              </div>
            );
          })}
        </>
      )}

      {(notCheckedInWorkers.length > 0) && (
        <>
          <h3 className="sectionheader">Nicht eingecheckt</h3>

          {notCheckedInWorkers.map(({ id, name, project }, i) => {
            return (
              <div key={i} className="columns tight is-clickable" onClick={onWorkerClicked.bind(null, id)}>
                <div className="column is-half">{name}</div>
                <div className="column is-half has-text-right">{project}</div>
              </div>
            );
          })}
        </>
      )}

      {(absentWorkers.length > 0) && (
        <>
          <h3 className="sectionheader">Abwesend</h3>

          <p className="absent">
            {absentWorkers.map(({ name, absence }, i) => {
              return `${name} (${mapAbsence(absence)})`;
            }).join(", ")}
          </p>
        </>
      )}
    </div>
  );
};

export default PresenceList;
