import * as React from "react";
import { useForm } from "react-hook-form";

import { Worker } from "../util";
import { ProjectList } from "./app";

interface CheckoutFormProps {
  worker: Worker;
  projects: ProjectList;
  onClose: (action?: "checkout" | "projectchange") => void;
}

const CheckoutForm : React.FC<CheckoutFormProps> = (props) => {
  const projectChange = useForm();
  const checkout = useForm();
  const { projects, worker, onClose } = props;

  const onProjectChange = ({ projectId }: { projectId: string }) => {
    fetch(`/worker/${worker.id}/change`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: worker.password,
        projectId
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      if (data.status) {
        onClose("projectchange");
      }
    });
  };

  const onCheckout = ({ correction }: { correction: number }) => {
    fetch(`/worker/${worker.id}/checkout`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: worker.password,
        correction
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      if (data.status) {
        onClose("checkout");
      }
    });
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box">
          <div className="columns">
            <div className="column is-half p-4" style={{ borderRight: "2px solid #F5F5F5" }}>
              <form onSubmit={projectChange.handleSubmit(onProjectChange)} className="p-4">
                <h4 className="title is-4">Projektwechsel</h4>
                <hr/>

                <div className="field">
                  <label className="label">Projekt</label>
                  <div className="control is-expanded">
                    <div className="select is-fullwidth">
                      <select defaultValue="" {...projectChange.register("projectId", { required: true })}>
                        <option value="" disabled={true}>
                          Projekt auswählen...
                        </option>
                        <optgroup label="Intern">
                          {projects.internal.map((project, i) => {
                            return (
                              <option key={i} value={project.id}>{project.name}</option>
                            );
                          })}
                        </optgroup>
                        <optgroup label="Chessboard">
                          {projects.external.filter((p) => p.visible).map((project, i) => {
                            return (
                              <option key={i} value={project.id}>{project.name}</option>
                            );
                          })}
                        </optgroup>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field is-grouped">
                  <div className="control">
                    <button
                      type="submit"
                      disabled={(projectChange.watch("projectId") || "") == ""}
                      className="button is-link"
                    >
                      Einchecken
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="column is-half p-4">
              <form onSubmit={checkout.handleSubmit(onCheckout)} className="p-4">
                <h4 className="title is-4">Auschecken</h4>
                <hr/>

                <div className="field">
                  <label className="label">Länge Mittagspause (Minuten)</label>
                  <div className="control is-expanded">
                    <input
                      className="input"
                      type="number"
                      defaultValue={60}
                      min={0}
                      step={15}
                      {...checkout.register("correction")}
                    />
                  </div>
                </div>

                <div className="field is-grouped">
                  <div className="control">
                    <button
                      type="submit"
                      className="button is-danger"
                    >
                      Auschecken
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={() => onClose()}></button>
    </div>
  );
};

export default CheckoutForm;
