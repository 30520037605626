import { createElement } from "react";
import { createRoot } from "react-dom/client";

import App from "./components/app";

import "bulma/css/bulma.css";
import "../../css/style.css";
import "../../css/frontend.css";

window.onload = () => {
  const domNode = document.getElementById("react");

  if (domNode) {
    const root = createRoot(domNode);
    root.render(createElement(App));
  }
};
