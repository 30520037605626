import * as React from "react";
import { useRef } from "react";

interface KeypadProps {
  onChange: (value: string) => void;
}

const Keypad: React.FC<KeypadProps> = (props) => {
  const { onChange } = props;
  const value = useRef("");

  const onButtonPressed = (num: number) => {
    return () => {
      value.current += num;
      onChange(value.current);
    };
  };

  return (
    <div>
      <div className="columns is-mobile is-centered is-1 is-variable">
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(1)}>
            1
          </button>
        </div>
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(2)}>
            2
          </button>
        </div>
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(3)}>
            3
          </button>
        </div>
      </div>

      <div className="columns is-mobile is-centered is-1 is-variable">
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(4)}>
            4
          </button>
        </div>
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(5)}>
            5
          </button>
        </div>
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(6)}>
            6
          </button>
        </div>
      </div>

      <div className="columns is-mobile is-centered is-1 is-variable">
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(7)}>
            7
          </button>
        </div>
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(8)}>
            8
          </button>
        </div>
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(9)}>
            9
          </button>
        </div>
      </div>

      <div className="columns is-mobile is-centered is-1 is-variable">
        <div className="column is-2">
          <button type="button" className="button is-fullwidth is-large" onClick={onButtonPressed(0)}>0</button>
        </div>
      </div>
    </div>
  );
};

export default Keypad;
