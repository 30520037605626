// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: bottom;
  background-color: #000000;
  background-repeat: no-repeat;
}

#app {
  color: #FFFFFF;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24pt;
}

.sectionheader {
  font-size: 20pt;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 20px;
}

.absent {
  font-size: 20pt;
  color: #909090;
}

.sectionheader:not(:first-child) {
  margin-top: 50px;
}

.tight {
  margin-bottom: 0!important;
}

.logo {
  position: absolute;
  top: 50px;
  left: 50px;
}

.logo img {
  width: 100px;
}
`, "",{"version":3,"sources":["webpack://./css/frontend.css"],"names":[],"mappings":"AAAA;EACE,yDAAoC;EACpC,2BAA2B;EAC3B,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,yCAAyC;EACzC,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;AACd","sourcesContent":["#react {\n  background-image: url(../img/bg.jpg);\n  background-position: bottom;\n  background-color: #000000;\n  background-repeat: no-repeat;\n}\n\n#app {\n  color: #FFFFFF;\n  font-family: Arial, Helvetica, sans-serif;\n  font-size: 24pt;\n}\n\n.sectionheader {\n  font-size: 20pt;\n  border-bottom: 1px solid #FFFFFF;\n  margin-bottom: 20px;\n}\n\n.absent {\n  font-size: 20pt;\n  color: #909090;\n}\n\n.sectionheader:not(:first-child) {\n  margin-top: 50px;\n}\n\n.tight {\n  margin-bottom: 0!important;\n}\n\n.logo {\n  position: absolute;\n  top: 50px;\n  left: 50px;\n}\n\n.logo img {\n  width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
