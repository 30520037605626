import * as React from "react";
import { useState } from "react";
import Keyboard from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";

const germanLayout = {
  default: [
    "^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}",
    "{tab} q w e r t z u i o p \u00FC +",
    "{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}",
    "{shift} < y x c v b n m , . - {shift}",
    "@ {space}"
  ],
  shift: [
    '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
    "{tab} Q W E R T Z U I O P \u00DC *",
    "{lock} A S D F G H J K L \u00D6 \u00C4 ' {enter}",
    "{shift} > Y X C V B N M ; : _ {shift}",
    "@ {space}"
  ]
};

interface GermanKeyboardProps {
  onChange: (value: string) => void;
}

const GermanKeyboard: React.FC<GermanKeyboardProps> = (props) => {
  const { onChange } = props;
  const [ layoutName, setLayoutName ] = useState("default");

  const onKeyPress = (key: string) => {
    if (layoutName == "shift") {
      setLayoutName("default");
    }

    if (key == "{lock}") {
      if (layoutName == "lock") {
        setLayoutName("default");
      } else {
        setLayoutName("lock");
      }
    }

    if (key == "{shift}") {
      if (layoutName == "shift") {
        setLayoutName("default");
      } else {
        setLayoutName("shift");
      }
    }
  };

  return (
    <Keyboard
      layout={germanLayout}
      layoutName={(layoutName == "shift" || layoutName == "lock") ? "shift" : "default"}
      onKeyPress={onKeyPress}
      onChange={onChange}
    />
  );
};

export default GermanKeyboard;
