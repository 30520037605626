import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Task, Worker } from "../util";
import { ProjectList } from "./app";
import GermanKeyboard from "./german_keyboard";

interface CheckinFormProps {
  worker: Worker;
  projects: ProjectList;
  onCheckin: (workerId: string) => void;
  onClose: () => void;
}

const CheckinForm: React.FC<CheckinFormProps> = (props) => {
  const { worker, projects, onCheckin, onClose } = props;

  const [ keyboardVisible, setKeyboardVisible ] = useState(false);
  const { register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm();

  const formSubmitted = handleSubmit(({ projectId, message }) => {
    fetch(`/worker/${worker.id}/checkin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        projectId, message,
        password: worker.password
      })
    }).then((res) => {
      if (res.ok) {
        onCheckin(worker.id);
        onClose();
      }
    }).catch((err) => {
      console.error(err);
      setError("checkin", {
        message: "Konnte Check-in nicht speichern"
      })
    });
  });

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box">
          <form onSubmit={formSubmitted} className="p-4">
            <h4 className="title is-4">Einchecken</h4>
            <hr/>

            {(errors.checkin) && (
              <article className="message is-danger">
                <div className="message-body">
                  {errors.checkin.message?.toString()}
                </div>
              </article>
            )}

            <div className="field">
              <label className="label">Name</label>
              <div className="control is-expanded">
                <input type="text" className="input is-static" readOnly={true} value={worker.name} />
              </div>
            </div>

            <div className="field">
              <label className="label">Projekt</label>
              <div className="control is-expanded">
                <div className="select is-fullwidth">
                  <select defaultValue="" {...register("projectId", { required: true })}>
                    <option value="" disabled={true}>
                      Projekt auswählen...
                    </option>
                    <optgroup label="Intern">
                      {projects.internal.map((project, i) => {
                        return (
                          <option key={i} value={project.id}>{project.name}</option>
                        );
                      })}
                    </optgroup>
                    <optgroup label="Chessboard">
                      {projects.external.filter((p) => p.visible).map((project, i) => {
                        return (
                          <option key={i} value={project.id}>{project.name}</option>
                        );
                      })}
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label">Ausgeführte Arbeit</label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Mitteilung..."
                  onFocus={setKeyboardVisible.bind(null, true)}
                  {...register("message")}
                />
              </div>
            </div>

            {(keyboardVisible) && (
              <div className="field">
                <GermanKeyboard
                  onChange={(value) => {
                    setValue("message", value);
                  }}
                />
              </div>
            )}

            <div className="field is-grouped">
              <div className="control">
                <button
                  type="submit"
                  disabled={(watch("projectId") || "") == ""}
                  className="button is-link"
                >
                  Einchecken
                </button>
              </div>
              <div className="control">
                <button type="reset" className="button is-link is-light" onClick={onClose}>
                  Abbrechen
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckinForm;
