import * as React from "react";
import { useEffect, useState } from "react";
import usePortal from "react-useportal";

import { Worker, Project, Task, useInterval } from "../util";
import CheckinForm from "./checkin_form";
import CheckoutForm from "./checkout_form";
import PasswordForm from "./password_form";
import PresenceList from "./presence_list";

export interface ProjectList {
  internal: Array<Project>;
  external: Array<Project>;
}

const App: React.FC = () => {
  const { isOpen, openPortal, closePortal, Portal } = usePortal();

  const [ action, setAction ] = useState<"checkin" | "checkout">();
  const [ workers, setWorkers ] = useState<Array<Worker>>([]);
  const [ projects, setProjects ] = useState<ProjectList>({ internal: [], external: [] });
  const [ tasks, setTasks ] = useState<Array<Task>>([]);

  const [ selectedWorker, setSelectedWorker ] = useState<Worker>();

  const fetchWorkers = () => {
    fetch("/workers").then((res) => {
      return res.json();
    }).then((workers: Array<Worker>) => {
      setWorkers(workers.filter((w) => w.active));
    });
  };

  useInterval(() => {
    fetchWorkers();
  }, 30000);

  useEffect(() => {
    fetchWorkers();

    fetch("/projects").then((res) => {
      return res.json();
    }).then((projects) => {
      setProjects(projects);
    });

    fetch("/tasks").then((res) => {
      return res.json();
    }).then((tasks) => {
      setTasks(tasks);
    });
  }, []);

  const onWorkerClicked = (id: string, e: React.MouseEvent<HTMLTableRowElement>) => {
    const worker = workers.find((w) => w.id == id);

    if (worker) {
      setSelectedWorker(worker);
      openPortal(e);
    }
  };

  const onPasswordCorrect = (password: string) => {
    if (selectedWorker) {
      setSelectedWorker({
        ...selectedWorker,
        password
      });

      if (selectedWorker.isPresent) {
        setAction("checkout");
      } else {
        setAction("checkin");
      }

      openPortal();
    }
  };

  const onCheckin = (id: string) => {
    console.log("checkin:", id);

    setWorkers(workers.map((w) => {
      if (w.id == id) {
        return {
          ...w,
          isPresent: true
        }
      }

      return w;
    }));

    setSelectedWorker(undefined);
    fetchWorkers();
  };

  const onCheckout = (id: string) => {
    console.log("checkout:", id);

    setWorkers(workers.map((w) => {
      if (w.id == id) {
        return {
          ...w,
          isPresent: false
        }
      }

      return w;
    }));

    setSelectedWorker(undefined);
  }

  return (
    <div id="app">
      <div className="logo">
        <img src="/static/img/logo.png" alt="logo" />
      </div>
      <section className="section">
        <div className="columns is-centered">
          <div className="column is-6">
            <PresenceList
              workers={workers}
              onWorkerClicked={onWorkerClicked}
            />
          </div>
        </div>
      </section>

      {(isOpen && selectedWorker) && (
        <Portal>
          {(selectedWorker.password) ? (
            (action == "checkin") ? (
              <CheckinForm
                worker={selectedWorker}
                projects={projects}
                tasks={tasks}
                onCheckin={onCheckin}
                onClose={closePortal}
              />
            ) : (
              <CheckoutForm
                worker={selectedWorker}
                projects={projects}
                onClose={(action) => {
                  if (action == "checkout") {
                    onCheckout(selectedWorker.id);
                  }

                  closePortal();
                  fetchWorkers();
                }}
              />
            )
          ) : (
            <PasswordForm
              worker={selectedWorker}
              onDone={onPasswordCorrect}
              onClose={closePortal}
            />
          )}
        </Portal>
      )}
    </div>
  );
};

export default App;
