import { useEffect, useRef } from "react";

export interface Worker {
  id: string;
  name: string;
  isPresent: boolean;
  active: boolean;
  project: string;
  absence?: string;
  password?: string;
}

export interface Project {
  id: string;
  name: string;
  visible: boolean;
}

export interface Task {
  id: string;
  name: string;
}

export function Range(start: number, end: number) {
  if (start === end) {
    return [];
  }

  if (end < start) {
    return new Array(start - end).fill(null).map((_, i) => {
      return start - i;
    });
  }

  return new Array(end - start).fill(null).map((_, i) => {
    return start + i;
  });
}

export function mapAbsence(reason?: string): string {
  if (!reason) {
    return "";
  }

  switch (reason) {
    case "school":
      return "Schule";
    case "sick":
      return "Krankenstand";
    case "holiday":
      return "Urlaub";
    default:
      return "abwesend";
  }
}

export function useInterval(callback: () => void, delay: number | null) {
  const currentCallback = useRef<() => void>();

  useEffect(() => {
    currentCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay) {
      const interval = setInterval(() => {
        currentCallback.current?.();
      }, delay);

      return () => {
        clearInterval(interval);
      };
    }
  }, [delay]);
}
